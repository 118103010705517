<template>
  <div class="container">
    <v-header deep theme></v-header>
    <div class="scroll-container money">
      <div class="getMoneyBox">
        <span class="boss">老板收钱啦!</span>
        <span class="tip_txt">可提现收益(元)</span>
        <span class="m_money">¥{{moneyFilter(wallet.remainProfitValue?wallet.remainProfitValue/10 : 0) }}</span>
        <div class="record-btn" @click="$router.push('/record?tab=CASH_OUT')">查看提现记录 <van-icon name="arrow"/> </div>
      </div>

      <div class="form_box">
        <div class="form">
          <span class="jin_e_t">提现金额</span>
          <div class="money_input">
            <input type="number" placeholder="¥ 请输入金额" v-model="price" oninput="value=value.replace(/[^\d]/g,'')">
          </div>
          <div class="tel_input">
            <div class="jia86">+86</div>
            <div class="tel">{{hidePhone(userInfo.mobile)}}</div>
          </div>
          <phone-code :phone="userInfo.mobile" captcha-type="cashOut" v-model="code"></phone-code>

          <div class="tips">
            <span class="red">*</span><span class="hui_txt">提现手机与用户账号关联，如需修改，请点击 账号设置 > 账号安全 进行变更。</span>
          </div>

          <div v-if="!hasAudingCashOut" class="btn send_btn" @click="pickUp"><span class="span">马上到账</span></div>
          <div v-else class="btn send_btn disabled"><span class="span">审核中</span></div>

          <div class="cash-out-tips">
            <div v-for="item in cashOutTip">{{item}}</div>
<!--            <div>①「收益严值」满1000后可提现。</div>-->
<!--            <div>②「充值严值」无法提现。</div>-->
<!--            <div>③  发起提现申请后微信零钱实时到账。</div>-->
<!--            <div>④  每人每天仅限提现1次。</div>-->
<!--            <div>⑤  单次提现限额500元（5000严值）。</div>-->
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {mapGetters} from 'vuex'
  import phoneCode from '@/components/phoneCode'
  import {cashOutV2Api} from "../../api/v2/cashOutV2Api";
  import {sleep} from "../../config/util";
  import {configV2Api} from "../../api/v2/configV2Api";

  export default {
    name: "pickUpMoney",
    computed: {...mapGetters(['userInfo', 'userVip', 'wallet'])},
    components: {phoneCode},
    data() {
      return {
        price: '',
        code: '',
        hasAudingCashOut: false,
        cashOutTip: []
      }
    },

    async created() {
      this.loadIfHasAudingCashOut().then();
      const [config ] = await configV2Api.findByCodes(['CASH_OUT_TIP'])
      this.cashOutTip = (config.value || '').split('\n')
    },

    methods: {

      async loadIfHasAudingCashOut() {
        this.hasAudingCashOut = await cashOutV2Api.findIfIHveAuditingCashOut();
      },

      async pickUp() {
        if (this.price <= 0) return;
        if (this.wallet.profitYanzhiValue / 10 < this.price) {
          return this.$toast.info('余额不足');
        }
        if (!this.code) {
          return this.$toast.info('请输入验证码');
        }

        try {
          await cashOutV2Api.startCashOut({
            captcha: this.code,
            cashOutYanzhi: this.price * 10
          })
          this.$toast.success('发起提现成功');
          await sleep(2000);
          this.$router.back();
        } catch (e) {
          this.$toast.info(e.message);
        }
        this.loadIfHasAudingCashOut();
      }
    }
  }
</script>

<style scoped lang="less">
  /* pages/personal/center/pickUpMoney/index.wxss */
  .money {
    background-color: #f7f7f7;
  }

  .getMoneyBox {
    height: 338px;
    background-color: #151d36;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(https://cdn.001ppt.cn/h5/static/image/cash-out-bg.png);
    background-size: cover;
  }

  .getMoneyBox .boss {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    margin-top: 50px;
  }

  .getMoneyBox .tip_txt {
    font-size: 12px;
    color: #fff;
    padding: 18px 0 4px 0;
  }

  .getMoneyBox .m_money {
    font-size: 46px;
    color: #fee09f;
    font-weight: bold;
  }

  .form_box {
    padding: 0 27px;
    margin-top: -100px;
  }

  .form_box .form {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px 25px;
  }

  .form .jin_e_t {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .form .money_input {
    background-color: #f3f3f5;
    border-radius: 4px;
    font-size: 14px;
    padding: 15px;
    margin-bottom: 15px;

    input {
      width: 100%;
      border: none;
      outline: none;
      background-color: #f3f3f5;
    }
  }

  .form .tel_input {
    display: flex;
    margin-bottom: 15px;
  }

  .form .tel_input .jia86 {
    background-color: #f3f3f5;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px 20px;
    color: #cdcfd5;
  }

  .phcolor {
    color: #D3D3D3;
  }

  .form .tel_input .tel {
    flex: 1;
    margin-left: 10px;
    background-color: #f3f3f5;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px;
    color: #cdcfd5;
  }

  .form .yanz_code {
    background-color: #f3f3f5;
    border-radius: 4px;
    padding: 10px;
    /*color: #cdcfd5;*/
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    input {
      width: 100%;
      border: none;
      outline: none;
      background-color: #f3f3f5;
    }
  }

  .form .yanz_code .input_code {
    flex: 1;
  }

  .form .yanz_code .tap_get_code {
    font-size: 12px;
    padding: 0 10px;
    border-left: 1px solid #999999;
  }

  .form .tips .red {
    font-size: 14px;
    font-weight: bold;
    color: #ff0000;
    margin: 0 5px;
    line-height: 150%;
  }

  .form .tips .hui_txt {
    font-size: 12px;
    line-height: 150%;
    color: #cdcfd5;
  }

  .send_btn {
    margin-top: 40px;
    background-color: #151d36;
    border-radius: 23px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .send_btn .span {
    color: #fff;
    font-size: 16px;
  }

  .record-btn{
    opacity: 0.3;
    color: rgba(255, 255, 255, 1);
    font-size: 10px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cash-out-tips{
    width: 269px;
    color: rgba(21, 29, 54, 1);
    font-size: 12px;
    text-align: left;
    margin: 19px auto;
  }
</style>
