<template>
  <div class="yanz_code">
    <input v-on:input="$emit('input', $event.target.value)" class="input_code" placeholder="请输入验证码"
           maxlength="6">
    <div class="tap_get_code">
      <span v-if="!isSend" @click="sendCode">获取验证码</span>
      <span v-else>{{timeDown}}s后重试</span>
    </div>
  </div>
</template>

<script>
  import {sleep} from "../config/util";

  const TIME = 60;
  import {user} from '@/api'
  import {cashOutV2Api} from "../api/v2/cashOutV2Api";

  export default {
    name: "phoneCode",
    data() {
      return {
        mobile: '',
        isSend: false,
        timeDown: TIME,
        code: ''
      }
    },
    props: {
      phone: {type: String, default: ''},
      captchaType: {type: String, required: false}
    },

    watch: {
      phone(val) {
        this.mobile = val;
      },

    },
    created() {
      this.mobile = this.phone;
    },

    methods: {
      async sendCode() {
        if (!this.mobile) {
          return this.$toast.info('手机号错误');
        }
        this.$loading();
        try {
          // 提现验证码
          if (this.captchaType === 'cashOut') {
            await cashOutV2Api.captcha();
          } else {
            const {code, data} = await user.getPhoneCode(this.mobile);
            if (code != 1) {
              return this.$toast.error('获取失败');
            }
          }
          this.countDown();
        }catch (e) {
          this.$toast.info(e.message);
        }
        finally {
          this.$loading.close();
        }
      },


      // 倒计时
      async countDown() {
        this.isSend = true;
        while (this.timeDown > 0) {
          await sleep(1000);
          this.timeDown--;
        }

        this.timeDown = TIME;
        this.isSend = false;
      },


    }
  }
</script>

<style lang="less">
  .yanz_code {
    background-color: #f3f3f5;
    border-radius: 4px;
    padding: 7px;
    color: #cdcfd5;
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 15px;

    input {
      border: none;
      outline: none;
      background-color: #f3f3f5;
    }
  }

  .input_code {
    flex: 1;
    width: 100%;
    font-size: 14px;
    color: #151d36;
    border: none;
    outline: none;
    background-color: #f3f3f5;
  }

  .yanz_code .tap_get_code {
    font-size: 12px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #999999;
  }

</style>
