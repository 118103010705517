import {v2Request} from "./v2Request";
import {formatDate} from "../../filters/dateFilter";


export const cashOutV2Api = {

  // 查找满多少钱才能提现
  async findConfig() {
    return v2Request.get(`cashOut/findConfig`)
  },


  // 发送提现验证码
  async captcha() {
    return v2Request.post(`cashOut/captcha`)
  },


  /**
   * 发起提现
   * @param cashOutYanzhi 提现金额
   * @param captcha 验证码
   */
  async startCashOut({cashOutYanzhi, captcha}) {
    return v2Request.post(`cashOut/startCashOut`, {cashOutYanzhi, captcha})
  },


  async findMyPage2({current, pageSize}) {
    return await v2Request.get(`cashOut/findMyPage`, {params: {current, pageSize}});
  },


  // 查找我的提现记录
  async findMyPage({current, pageSize}) {
    const res = await v2Request.get(`cashOut/findMyPage`, {params: {current, pageSize}});
    const records = res.records || [];

    const monthToRows = {};
    for (const r of records) {
      const createTime = new Date(r.createTime);
      const month = formatDate(createTime, 'yyyy年MM月');
      monthToRows[month] = monthToRows[month] || [];
      monthToRows[month].push(r);
    }

    const rst = [];
    for (const month in monthToRows) {
      const rows = monthToRows[month];
      const item = {
        date: month,
        subRecords: rows
      };
      rst.push(item);
    }
    res.records = rst;
    return res;
  },


  async findIfIHveAuditingCashOut() {
    return await v2Request.get(`cashOut/findIfIHveAuditingCashOut`);
  }

};
